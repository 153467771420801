<template>
  <div 
    class="relative h-full w-full max-h-screen max-w-screen overflow-hidden flex border-8 border-primary-dark"
  >
    <div class="flex-1 relative h-screen flex flex-col">
      <header class="w-full h-20 xs:h-40 flex justify-center xs:ml-10">
        <div class="xs:w-auto md:w-2/24 h-auto md:h-full">
          <div class="h-full w-full flex flex-col justify-center items-center">
            <div class="flex">
              <h1 class="w-7 md:w-14 h-7 md:h-14 flex justify-center items-center text-white font-sans text-2xl md:text-4xl">G</h1>
              <h1 class="w-7 md:w-14 h-7 md:h-14 flex justify-center items-center text-white font-sans text-2xl md:text-4xl">R</h1>
            </div>

            <div class="flex">
              <h1 class="w-7 md:w-14 h-7 md:h-14 flex justify-center items-center text-white font-sans text-2xl md:text-4xl">X</h1>
              <h1 class="w-7 md:w-14 h-7 md:h-14 flex justify-center items-center text-white font-sans text-2xl md:text-4xl">L</h1>
            </div>
          </div>
        </div>

        <!-- <div 
          class="w-6/24 md:w-2/24 h-full"
          @mouseover.stop.prevent="showProjectWording"
          @mouseout.stop.prevent="resetProjectWording"
        >
          <div class="h-full w-full flex flex-col justify-center items-center">
            <menu-button
              @click="toggleMenu"
              :is-open="isMenuOpen"
            ></menu-button>
          </div>
        </div> -->
      </header>

      <div 
        ref="view"
        class="app-view z-10 flex-1 overflow-hidden"
      >
        <div 
          :class="[
            'app-view--wrapper overflow-hidden relative box-border h-full w-full',
            {
              'pb-10 big:pb-20': hasFooter,
              'pb-0 big:pb-0': !hasFooter,
            }
          ]"
        >
          <div 
            :class="[
              'app-view--content overflow-auto relative box-border transition-all smooth duration-150 h-full',
            ]"
          >
            <router-view #default="{ Component }">
              <component :is="Component"/>
            </router-view>

            <transition
              enter-active-class="transition-opacity duration-500 ease-smooth"
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="transition-opacity duration-500"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div v-show="isMenuOpen">
                MENU
              </div>
            </transition>
          </div>
        </div>
      </div>

      <div   
        ref="footer"
        :class="[
          'relative z-0 w-full overflow-visible transition-all smooth duration-500',
          {
            'h-32': hasFooter,
            'h-0': !hasFooter,
          }
        ]"
      >
        <portal-target 
          name="footer"
          class="h-full w-full"
        ></portal-target>
      </div>
    </div>
  </div>
</template>

<script>
  import { computed, provide, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { provideEngine, Noise } from '@composables/webgl/use-engine'
  import { useBackground } from '@composables/webgl/use-background'
  import { useObserver } from '@composables/utils/use-observer'
  import { useProjects } from '@composables/projects/use-projects'
  import MenuButton from './menu-button'
  
  export default {
    setup () {
      const route = useRoute()

      const Projects = useProjects()
      const footer = ref(null)

      provide('projects', Projects)

      const engine = provideEngine({
        effects: {
          // noise: Noise,
        }
      })
      
      const { state: projects } = Projects
      const { hasChildren: hasFooter } = useObserver(footer)
      
      const isMenuOpen = ref(false)

      engine.onUpdate(elapsed => {
        if (engine.effects.noise) {
          engine.effects.noise.uniforms['amount'].value += 0.01
        }
      })

      engine.init()

      const view = ref(null)
      const updatingRoute = ref(false) 

      watch(() => route, () => {
        if (!updatingRoute.value && view.value) {
          updatingRoute.value = true 

          view.value.addEventListener('transitionend', updateFrame)
        }
      })

      const delay = 1500 - (Date.now() - window.isLoading)
      
      setTimeout(() => {
        document.body.classList.remove('is-loading')
      }, delay)

      function updateFrame () {
        view.value.removeEventListener('transitionend', updateFrame)
      }

      function toggleMenu () {
        isMenuOpen.value = !isMenuOpen.value
      }

      return {
        projects,
        view,
        footer,
        hasFooter,
        isMenuOpen,
        toggleMenu,
      }
    },

    components: {
      MenuButton,
    },
  }
</script>

<style lang="scss">
  body > canvas {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0px;
    left: 0px;
    z-index: -1;
  }

  .app-view {
    position: relative;
    box-sizing: border-box;
    opacity: 1;
    transition: opacity 1s linear;
    max-height: calc(100vh - 10rem);
    height: 100vh;
    overflow: hidden;

    &--wrapper {
      position: relative;
      top: 0px; 
      left: 0px;
      max-height: 100%;
      height: 100%;

      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 5rem;
        height: calc(100% - 10rem);
        width: 1px;
        z-index: 7;
        background: $color-primary-dark;
        transform-origin: top center;
        transform: scale3d(1, 0, 1);
        transition: transform 0.3s cubic-bezier(.25, .05, .1, .94);
        // animation: frameYEnter 1s cubic-bezier(.25, .05, .1, .94) forwards;
      }

      &:before {
        left: 5rem;
      }

      &:after {
        right: 5rem;
      }
    }

    &--wrapper {
      position: relative;
      max-height: calc(100vh - 10rem);
      height: 100%;
    }

    &.route-update {
      opacity: 0.99;

      &--wrapper {
        &:before,
        &:after {
          animation: frameYUpdate 1s cubic-bezier(.25, .05, .1, .94) forwards;
        }
      }
    }
  }

  // animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;

  @keyframes frameYEnter {
    0% {
      transform: scale3d(1, 0, 1);
    }
    
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes frameYUpdate {
    0% {
      transform-origin: bottom center;
      transform: scale3d(1, 1, 1);
    }
    
    40% {
      transform-origin: bottom center;
      transform: scale3d(1, 0, 1);
    }

    50% {
      transform-origin: top center;
      transform: scale3d(1, 0, 1);
    }

    100% {
      transform-origin: top center;
      transform: scale3d(1, 1, 1);
    }
  }

  .fade-leave-active,
  .fade-enter-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-active {
    opacity: 0;
  }
</style>