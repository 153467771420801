import { computed, onMounted, onUnmounted, reactive } from 'vue'
import { injectEngine } from './use-engine'
import { PlaneGeometry, MeshBasicMaterial, DoubleSide, Mesh } from 'three'

export function useBackground (params = {}) {
  const options = {
    engine: injectEngine(),
    width: window.innerWidth, 
    height: window.innerHeight, 
    resolution: 32,
  }

  const engine = options.engine 

  const geometry = new PlaneGeometry(options.width, options.height, options.resolution)
  const material = new MeshBasicMaterial({
    color: 0x181818, 
    side: DoubleSide 
  })

  const mesh = new Mesh(geometry, material)

  onMounted(() => {
    engine.addMeshToScene(mesh)
  })

  onUnmounted(() => {
    engine.removeMeshFromScene(mesh)
  })

  return {
    mesh,
    geometry,
    material,
  }
}

export default useBackground