import { h, defineComponent, reactive, computed, nextTick, onMounted, onUpdated, onBeforeUnmount, watch } from 'vue'
import { wormhole } from './wormhole'
import { combinePassengers } from './utils'

export default defineComponent({
  name: 'portalTarget',

  props: {
    multiple: { type: Boolean, default: false },
    name: { type: String, required: true },
    slim: { type: Boolean, default: false },
    slotProps: { type: Object, default: () => ({}) },
    tag: { type: String, default: 'div' },
    transition: { 
      type: [String, Object, Function],
    },
  },

  setup (props, context) {
    function getChildren () {
      return Array.isArray(passengers.value) && passengers.value?.length !== 0
        ? passengers.value
        : context.slots.default ? context.slots.default() : []
    }

    function isNoWrapper () {
      const noWrapper = props.slim && !props.transition

      if (noWrapper && getChildren().length > 1) {
        console.warn(
          '[f-portal]: PortalTarget with `slim` option received more than one child element.'
        )
      }

      return noWrapper
    }

    const state = reactive({
      disabled: props.disabled,
      transports: wormhole.transports,
      firstRender: true,
    })

    const ownTransports = computed(() => {
      const transports = state.transports[props.name] || []

      if (props.multiple) {
        return transports
      }
      
      return transports.length === 0 ? [] : [transports[transports.length - 1]]
    }) 
    
    const passengers = computed(() => {
      return combinePassengers(ownTransports.value, props.slotProps) || []
    })

    watch(() => ownTransports.value, () => {
      context.emit('change', getChildren().length > 0)
    })

    watch(() => props.name, (newValue, oldValue) => {
      wormhole.unregisterTarget(oldValue)
      wormhole.registerTarget(newValue, { ...context, ...props })
    })

    nextTick(() => {
      wormhole.registerTarget(props.name, { ...context, ...props })
    })

    onMounted(() => {
      if (props.transition) {
        nextTick(() => {
          // only when we have a transition, because it causes a re-render
          state.firstRender = false
        })
      }
    })

    onUpdated(() => {

    })

    onBeforeUnmount(() => {
      wormhole.unregisterTarget(props.name)
    })

    return () => {
      const noWrapper = isNoWrapper()
      const children = getChildren()
      const Tag = props.transition || props.tag

      return noWrapper
        ? children[0]
        : props.slim && !Tag
        ? h()
        : h(
            Tag,
            {
              tag: props.transition && props.tag ? props.tag : undefined,
              class: { 'f-portal-target': true },
            },
            children
          )
    }
  }
})