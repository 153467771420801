<template>
  <div class="layout-fullscreen">
    <h1>Fullscreen</h1>
    
    <router-view></router-view>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss">
  .layout-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 70;
  }
</style>