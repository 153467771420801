import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'

window.requestAnimationFrame = window.requestAnimationFrame 
  || window.mozRequestAnimationFrame 
  || window.webkitRequestAnimationFrame 
  || window.msRequestAnimationFrame

export default class WebGLEngine {
  constructor (container = document.body, params = {}) {
    this.params = Object.assign({
      effects: {},
    }, params)

    this.container = container  

    this.scene = null 
    this.renderer = null 
    this.camera = null 

    this.composer = null

    this.running = false
    this.started = 0

    window.addEventListener('resize', this.resize.bind(this), false)
  }

  setup (scene, renderer, camera) {
    this.scene = scene
    this.renderer = renderer
    this.camera = camera
  }

  init () {
    if (!this.scene || !this.renderer || !this.camera) { 
      throw new Error(`[WebGLEngine] Missing a required component (scene, renderer or camera)`)
    }

    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(new RenderPass(this.scene, this.camera))

    for (const effectName in this.params.effects) {
      if (Object.prototype.hasOwnProperty.call(this.params.effects, effectName)) {
        const effect = this.params.effects[effectName]

        this.composer.addPass(effect)
      }
    }

    this.container.appendChild(this.renderer.domElement)
    
    this.play()
  }

  play () {
    this.running = true
    this.started = Date.now()

    this.loop()
  }

  stop () {
    this.running = false
    this.started = 0
  }

  loop () {
    if (this.running) {
      window.requestAnimationFrame(this.loop.bind(this))
    }

    const elapsed = Date.now() - this.started

    this.update(elapsed, {
      effects: this.params.effects 
    })

    this.composer.render()
  }

  update (elapse, context) {
    
  }

  resize () {
    if (this.camera && this.renderer) {
      const width = window.innerWidth
      const height = window.innerHeight
  
      this.camera.resize(width, height)
  
      this.renderer.setSize(width, height)
    }
  }

  get edges () {
    const fov      = this.camera.fov / 180 * Math.PI / 2
    const distance = this.camera.position.distanceTo(this.scene.position)

    const vertical = distance * Math.tan(this.camera.fov * 0.5 * Math.PI / 180)
    const horizontal = vertical * this.camera.aspect
    
    return {
      top: -vertical, 
      bottom: vertical,
      left: -horizontal - 2,
      right: horizontal,
    }
  }
}