import deepMerge from '@utils/deep-merge'
import { 
  WebGLRenderer as Renderer,
  PCFSoftShadowMap,
} from 'three'

export default class WebGLRenderer extends Renderer {
  constructor (options = { antialias: true, alpha: true }, params = {}) {
    super(options)

    this.params = deepMerge({
      background: 0xffffff,
      opacity: 0, 
      width: window.innerWidth,
      height: window.innerHeight,
      pixelRatio: window.devicePixelRatio || 1,
      shadowMap: {
        enabled: true,
        type: PCFSoftShadowMap,
      },
      preserveDrawingBuffer: true,
    }, params)

    this.setClearColor(this.params.background, this.params.opacity)
    this.setSize(this.params.width, this.params.height)
    this.setPixelRatio(this.params.pixelRatio)
    this.shadowMap.enabled = this.params.shadowMap.enabled
    this.shadowMap.type = this.params.shadowMap.type
    this.preserveDrawingBuffer = this.params.preserveDrawingBuffer

    if (process.env.NODE_ENV !== 'production') {
      console.debug(`[WebGLRenderer] Loaded with options:`, JSON.stringify(options, null, 2))
      console.debug(`[WebGLRenderer] Loaded with params:`, JSON.stringify(this.params, null, 2))
    }
  }
}
