import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const components = require.context('.', true, /\.global\.(vue|js)$/)
const Components = {}

components.keys().forEach(filename => {
  const component = components(filename)

  const name = upperFirst(
    camelCase(
      filename
        .split('/')
        .pop()
        .replace(/\.global\.(vue|js)$/, '')
    )
  )

  Components[name] = component.default || component
})

export default {
  install: function install (app, options = { base: 'B' }) {
    for (const name in Components) {
      if (Object.prototype.hasOwnProperty.call(Components, name)) {
        let component = Components[name]

        if (component.install) {
          component = component.install(app)
        }
      
        app.component(`${options.base || 'B'}${name}`, component)
      }
    }
  }, 
}