<script>
  import { h } from 'vue'

  export default {
    props: {
      tag: {
        type: String,
        default: 'h1',
      },

      word: {
        type: String, 
        default: '',
      },

      alphabet: {
        type: [String, Array],
        default: () => ([
          'a', 'b', 'c', 'd', 'e', 
          'f', 'g', 'h', 'i', 'j', 
          'k', 'l', 'm', 'n', 'o',
          'p', 'q', 'r', 's', 't',
          'u', 'v', 'w', 'x', 'y', 
          'z',
        ])
      },

      duration: {
        type: Number, 
        default: 500,
      },

      step: {
        type: Number, 
        default: 30,
      },

      maxLength: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        innerWord: this.word,
        started: 0,
      }
    },

    methods: {
      loop () {
        const now = Date.now()
        const elapsed = now - this.started
        const lastStep = now - this.clock

        if (elapsed < this.duration) {
          if (lastStep >= this.step) {
            this.innerWord = Array.apply(null, { 
              length: this.maxLength || this.word.length 
            }).map(() => {
              return this.random()
            }).join('')
  
            this.clock = Date.now()
          }

          window.requestAnimationFrame(this.loop.bind(this))
        } else {
          this.innerWord = this.word
        }
      },
      
      updateWord () {
        this.started = Date.now()
        this.clock = Date.now()

        this.loop()
      },

      random () {
        const index = Math.floor(Math.random() * (this.alphabet.length - 0 + 1) + 0)

        return this.alphabet[index]
      },
    },

    watch: {
      word () {
        this.updateWord()
      },
    },

    render () {
      return h(
        this.tag, 
        {
          class: {
            'rolling-title': true,
          },
        },

        this.innerWord.split('').map((letter, index) => {
          return h(
            'span', 
            {
              key: index,
            },
            letter
          )
        }),
      )
    },
  }
</script>