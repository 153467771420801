import useLazyRoute from '@composables/routing/use-lazy-route'

export default {
  path: 'projects/:project_id?',
  name: 'projects',
  
  components: {
    default: useLazyRoute({
      component: () => import(/* webpackChunkName: "page-projects" */'@pages/projects')
    }),
  },
}