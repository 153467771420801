import { h, toRefs, defineComponent, reactive } from 'vue'
import { freeze, inBrowser, stableSort } from './utils'

export const Wormhole = defineComponent({
  setup () {
    const state = reactive({
      transports: {},
      targets: {},
      sources: {},
      trackInstances: inBrowser
    })

    function open (transport) {
      const { to, from, passengers, order = Infinity } = transport

      if (!to || !from || !passengers) {
        return
      }

      const newTransport = {
        to,
        from,
        passengers: freeze(passengers),
        order,
      }
      const keys = Object.keys(state.transports)

      if (keys.indexOf(to) === -1) {
        state.transports[to] = []
      }

      const currentIndex = getTransportIndex(newTransport)
      // Copying the array here so that the PortalTarget change event will actually contain two distinct arrays
      const newTransports = state.transports[to].slice(0)
      if (currentIndex === -1) {
        newTransports.push(newTransport)
      } else {
        newTransports[currentIndex] = newTransport
      }

      state.transports[to] = stableSort(
        newTransports,
        (a, b) => a.order - b.order
      )
    } 

    function close(transport, force = false) {
      const { to, from } = transport

      if (!to || (!from && force === false)) {
        return
      }

      if (!state.transports[to]) {
        return
      }

      if (force) {
        state.transports[to] = []
      } else {
        const index = getTransportIndex(transport)

        if (index >= 0) {
          // Copying the array here so that the PortalTarget change event will actually contain two distinct arrays
          const newTransports = state.transports[to].slice(0)
          newTransports.splice(index, 1)
          state.transports[to] = newTransports
        }
      }
    }

    function registerTarget (target, vm, force = false) {
      if (!inBrowser) {
        return
      }

      if (state.trackInstances && !force && state.targets[target]) {
        console.warn(`[f-portal]: Target ${target} already exists`)
      }
      
      state.targets[target] = Object.freeze([vm])
    }

    function unregisterTarget (target) {
      delete state.targets[target]
    }

    function registerSource(source, vm, force = false) {
      if (!inBrowser) {
        return
      }

      if (state.trackInstances && !force && state.sources[source]) {
        console.warn(`[f-portal]: source ${source} already exists`)
      }
      
      state.sources[source] = Object.freeze([vm])
    }

    function unregisterSource(source) {
      delete state.sources[source]
    }

    function hasTarget (to) {
      return !!(state.targets[to] && state.targets[to][0])
    }

    function hasSource (to) {
      return !!(state.sources[to] && state.sources[to][0])
    }

    function hasContentFor (to) {
      return !!state.transports[to] && !!state.transports[to].length
    }

    function getTransportIndex({ to, from }) {
      for (const i in state.transports[to]) {
        if (state.transports[to][i].from === from) {
          return +i
        }
      }
      return -1
    }

    return {
      ...toRefs(state),
      open,
      close,
      registerTarget,
      unregisterTarget,
      registerSource,
      unregisterSource,
      hasTarget,
      hasSource,
      hasContentFor,
    }
  }
})

const wormhole = Wormhole.setup()

export { wormhole }