<template>
  <span
    :class="['nice-arrow', {
      'is-left': isLeft,
    }]"
    :style="{
      backgroundColor: color,
      borderColor: color,
    }"
  >
    <span 
      class="arrow" 
      :style="{
        borderColor: color,
      }"
    ></span>
  </span>
</template>

<script>
  const LEFT = 'left'
  const RIGHT = 'right'

  export default {
    props: {
      color: {
        type: String,
        default: '',
      },

      direction: {
        type: String,
        default: RIGHT,
      },
    },

    computed: {
      isLeft () {
        return this.direction === LEFT
      },
    },
  }
</script>

<style lang="scss">
  .nice-arrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: max-content;
    border-width: 0px;

    .arrow {
      position: relative;
      display: inline-block;
      height: 1px;
      width: 64px;
      transform: translate3d(0, -8px, 0);
      border-width: 0px;

      &:before {
        content: "";
        position: absolute;
        right: 2px;
        height: 16px;
        width: 16px;
        border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: inherit;
        transform: rotate(-45deg);
        transition: border-color 0.3s ease;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0px;
        height: 1px;
        width: 64px;
        background: inherit;
        transition: background-color 0.3s ease;
        transform: translate3d(0, 8px, 0);
      }
    }

    &.is-left {
      .arrow {
        &:before {
          left: 2px;
          transform: rotate(135deg);
        }
      }
    }

    &:hover {
      .arrow {
        &:before {
          // border-right: 1px solid; // $color-white;
          // border-bottom: 1px solid; // $color-white;
        }

        &:after {
          // background: $color-white;
        }
      }
    }
  }

  *:hover > .nice-arrow {
    .arrow {
      &:before {
        // border-right: 1px solid; // $color-white;
        // border-bottom: 1px solid; // $color-white;
      }

      &:after {
        // background: $color-white;
      }
    }
  }
</style>