import { computed, inject, onMounted, onUnmounted, provide, reactive, ref } from 'vue'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { GlitchPass } from 'three/examples/jsm/postprocessing/GlitchPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import EventsEmitter from 'events'
import WebGLEngine from '@controllers/webgl/engine'
import WebGLScene from '@controllers/webgl/core/scene'
import WebGLRenderer from '@controllers/webgl/core/renderer'
import WebGLPerspectiveCamera from '@controllers/webgl/core/camera'
import NoiseVertex from '@controllers/webgl/shaders/noise.vert'
import NoiseFragment from '@controllers/webgl/shaders/noise.frag'

const WEBGL_ENGINE = Symbol('WEBGL_ENGINE')

export const Noise = new ShaderPass({
  uniforms: {
    tDiffuse: { value: null },
    amount: { value: 0.0 }
  },
  vertexShader: NoiseVertex,
  fragmentShader: NoiseFragment
})

export function useEngine (params = {}) {
  const options = Object.assign({
    container: document.querySelector('body'),

    camera: null,

    rendererParams: { 
      background: 0x000000, 
      opacity: 0 
    },

    effects: {},
  }, params)

  const bus = new EventsEmitter()

  const container = options.container
  const scene = new WebGLScene({ debug: false })
  const renderer = new WebGLRenderer(
    { antialias: true, alpha: true }, 
    options.rendererParams,
  )
  const camera = options.camera || new WebGLPerspectiveCamera()

  let composer = null

  const state = reactive({
    initialized: false,
    running: false,
    startedAt: 0,
    edges: computed(() => {
      const fov = camera.fov / 180 * Math.PI / 2
      const distance = camera.position.distanceTo(scene.position)
  
      const vertical = distance * Math.tan(camera.fov * 0.5 * Math.PI / 180)
      const horizontal = vertical * camera.aspect
      
      return {
        top: -vertical, 
        bottom: vertical,
        left: -horizontal - 2,
        right: horizontal,
      }
    }),
  })

  function init () {
    if (state.initialized) {
      return console.warn(`Engine has already been initialized.`)
    }

    composer = new EffectComposer(renderer)

    composer.addPass(new RenderPass(scene, camera))

    for (const effectName in options.effects) {
      if (Object.prototype.hasOwnProperty.call(options.effects, effectName)) {
        const effect = options.effects[effectName]

        composer.addPass(effect)
      }
    }

    container.appendChild(renderer.domElement)

    play()

    bus.emit('init', { state, scene, renderer, camera })
  }

  function play () {
    state.startedAt = Date.now()
    state.running = true 

    bus.emit('play')

    loop()
  }

  function loop () {
    bus.emit('loop', { state, scene, renderer, camera })

    update(Date.now() - state.startedAt, {
      effects: options.effects 
    })

    composer.render()

    if (state.running) {
      window.requestAnimationFrame(loop)
    }
  }

  function update (elapsed, options) {
    bus.emit('update', elapsed, options)
  }

  function resize (event) {
    const width = window.innerWidth
    const height = window.innerHeight

    camera.resize(width, height)
    renderer.setSize(width, height)

    bus.emit('resize', event)
  }

  function onInit (fn) {
    bus.on('init', fn)
  }

  function onUpdate (fn) {
    bus.on('update', fn)
  }

  function onLoop (fn) {
    bus.on('loop', fn)
  }

  function onPlay (fn) {
    bus.on('play', fn)
  }

  function onStop (fn) {
    bus.on('stop', fn)
  }

  function onResize (fn) {
    bus.on('resize', fn)
  }

  function offInit (fn) {
    bus.off('init', fn)
  }

  function offUpdate (fn) {
    bus.off('update', fn)
  }

  function offLoop (fn) {
    bus.off('loop', fn)
  }

  function offPlay (fn) {
    bus.off('play', fn)
  }

  function offStop (fn) {
    bus.off('stop', fn)
  }

  function offResize (fn) {
    bus.off('resize', fn)
  }

  function addMeshToScene (mesh) {
    scene.add(mesh)
  }

  function removeMeshFromScene (mesh) {
    scene.remove(mesh)
  }

  function addMeshesToScene (meshes) {
    meshes.forEach(addMeshToScene)
  }

  function removeMeshesFromScene (meshes) {
    meshes.forEach(removeMeshFromScene)
  }
  
  onMounted(() => {
    window.addEventListener('resize', resize, false)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', resize, false)
  })

  return {
    state,
    bus,
    scene,
    renderer,
    camera,
    effects: options.effects,
    init,
    onInit,
    offInit,
    onUpdate,
    offUpdate,
    onLoop,
    offLoop,
    onPlay,
    offPlay,
    onStop,
    offStop,
    onResize,
    offResize,
    addMeshToScene,
    removeMeshFromScene,
    addMeshesToScene,
    removeMeshesFromScene,
  }
}

export function provideEngine (params) {
  const engine = useEngine(params)

  provide(WEBGL_ENGINE, engine)

  return engine
}

export function injectEngine (params) {
  return inject(WEBGL_ENGINE)
}

export default useEngine