import { defineAsyncComponent, h } from 'vue'
import LoadingComponent from '@components/async-route/loading'
import ErrorComponent from '@components/async-route/error'

export default function useLazyRoute ({ 
  component, 
  loading = LoadingComponent, 
  error = ErrorComponent,
}) {
  const AsyncHandler = defineAsyncComponent({
    loader: component,
    loadingComponent: loading,
    errorComponent: error,
    delay: 200,
    timeout: 10000,
  })

  return () => {
    return Promise.resolve({
      render (props, context) {
        return h(AsyncHandler, context.attrs, context.slots)
      }
    })
  }
}