import { 
  Scene, 
  Geometry, 
  LineBasicMaterial, 
  Line, 
  Vector3, 
} from 'three'

export default class WebGLScene extends Scene {
  constructor (options = {}) {
    super(options)

    if (options.debug) {
      this.debug()
    }
  }

  debug (length = Math.max(window.innerWidth, window.innerHeight)) {
    this.addAxis(
      new Vector3(-length, 0, 0),
      new Vector3(length, 0, 0),
      0xFF0000
    )

    this.addAxis(
      new Vector3(0, -length, 0),
      new Vector3(0, length, 0),
      0x00FF00
    )

    this.addAxis(
      new Vector3(0, 0, -length),
      new Vector3(0, 0, length),
      0xFF0000
    )
  }

  addAxis (fromPoint, toPoint, color = 0xFF0000) {
    const geometry = new Geometry()
    const material = new LineBasicMaterial({ color: color })

    geometry.vertices.push(fromPoint, toPoint)

    this.add(new Line(geometry, material))
  }
}
