import { computed, reactive } from 'vue'
import { projects } from '@assets/data/projects.json'
import pad from '@utils/pad'

export function useProjects () {
  const state = reactive({
    items: projects,

    currentProject: computed(() => state.items[state.currentIndex]),
    previousProject: computed(() => 
      state.hasPrevious ? state.items[state.currentIndex - 1] : state.items[state.items.length - 1]
    ),
    nextProject: computed(() => 
      state.hasNext ? state.items[state.currentIndex + 1] : state.items[0]
    ),

    currentIndex: 0,
    currentPage: computed(() => {
      const [unit, decade] = pad(state.currentIndex + 1).split('').reverse()
      
      return { decade, unit }
    }),
    currentTotal: computed(() => pad(state.items.length)),
    hasNext: computed(() => state.currentIndex + 1 < state.items.length),
    hasPrevious: computed(() => state.currentIndex - 1 >= 0),
  })

  function loadPrevious () {
    if (state.hasPrevious) {
      state.currentIndex--
    } else {
      state.currentIndex = state.items.length - 1
    }
  }

  function loadNext () {
    if (state.hasNext) {
      state.currentIndex++
    } else {
      state.currentIndex = 0
    }
  }

  function setProjectById (id) {
    if (id) {
      const index = state.items.findIndex(project => project.id === id)

      if (index >= 0) {
        state.currentIndex = index
      }
    }
  }

  return {
    state,
    loadPrevious,
    loadNext,
    setProjectById,
  }
}

export default useProjects