import deepMerge from '@utils/deep-merge'
import { OrthographicCamera, Vector3 } from 'three'

const LEFT = window.innerWidth / -2 
const RIGHT = window.innerWidth / 2 
const TOP = window.innerHeight / 2
const BOTTOM = window.innerHeight / -2 
const NEAR = -1
const FAR = 10000

class WebGLOrthographicCamera extends OrthographicCamera {
  constructor (
    near = NEAR, 
    far = FAR, 
    left = LEFT, 
    right = RIGHT, 
    top = TOP, 
    bottom = BOTTOM, 
    params = {}
  ) {
    super(left, right, top, bottom, near, far)

    this.params = deepMerge({
      name: 'WebGLOrthographicCamera',
      position: {
        x: 0,
        y: 0, 
        z: 0,
      },
      target: {
        x: 0,
        y: 0, 
        z: 0,
      },
    }, params)

    this.name = this.params.name

    this.top = 0
    this.left = 0

    this.position.x = this.params.position.x,
    this.position.y = this.params.position.y,
    this.position.z = this.params.position.z

    this.lookAt(new Vector3(
      this.params.target.x,
      this.params.target.y,
      this.params.target.z
    ))

    if (process.env.NODE_ENV !== 'production') {
      console.debug(`[WebGLOrthographicCamera] Loaded with options:`, JSON.stringify({ far, near }, null, 2))
      console.debug(`[WebGLOrthographicCamera] Loaded with params:`, JSON.stringify(this.params, null, 2))
    }
  }

  resize (width, height) {
    this.left = width / -2 
    this.right = width / 2 
    this.top = height / 2
    this.bottom = height / -2 
    this.updateProjectionMatrix()
  }
}


export default WebGLOrthographicCamera