import { h, defineComponent, ref, reactive, nextTick, onMounted, onUpdated, onBeforeUnmount, watch } from 'vue'
import { wormhole } from './wormhole'

let _id = 1

export default defineComponent({
  name: 'portal',

  props: {
    disabled: { type: Boolean },
    name: { type: String, default: () => String(_id++) },
    order: { type: Number, default: 0 },
    slim: { type: Boolean },
    slotProps: { type: Object, default: () => ({}) },
    tag: { type: String, default: 'DIV' },
    to: {
      type: String,
      default: () => String(Math.round(Math.random() * 10000000)),
    },
  },

  setup (props, context) {
    function sendUpdate () {
      const slotContent = normalizeSlots()

      if (slotContent) {
        const transport = {
          from: props.name,
          to: props.to,
          passengers: [...slotContent],
          order: props.order,
        }
        
        wormhole.open(transport)
      } else {
        clear()
      }
    }

    function clear (target) {
      const closer = {
        from: props.name,
        to: target || props.to,
      }

      wormhole.close(closer)
    }

    function normalizeSlots () {
      return context.slots.default && context.slots.default() || []
    }
    
    function normalizeOwnChildren (children) {
      return typeof children === 'function'
        ? children(state.slotProps)
        : children
    }

    const state = reactive({
      name: 'portal',
      disabled: props.disabled,
    })

    watch(() => props.to, (newValue, oldValue) => {
      oldValue && oldValue !== newValue && clear(oldValue)

      sendUpdate()
    })
  
    onMounted(() => {
      if (!state.disabled) {
        sendUpdate()
      }
    })

    onUpdated(() => {
      if (state.disabled) {
        clear()
      } else {
        sendUpdate()
      }
    })

    onBeforeUnmount(() => {
      wormhole.unregisterSource(props.name)

      clear()
    })

    nextTick(() => {
      wormhole.registerSource(props.name, { ...context, ...props })
    })

    return () => {
      const children = normalizeSlots() || []
      const Tag = ref(props.tag)

      if (children && props.disabled) {
        return children.length <= 1 && props.slim 
          ? normalizeOwnChildren(children)[0]
          : `<Tag>${normalizeOwnChildren(children)}</Tag>`

      } else {
        return props.slim ? h() : h(Tag.value, {
          class: { 'f-portal': true },
          style: { 
            display: 'none', 
          },
          key: 'f-portal-placeholder',
        })
      }
    }
  }
})