import { computed, nextTick, onMounted, onUnmounted, reactive, ref } from 'vue'
import { useCanvasEngine } from './use-canvas-engine'
import anime from 'animejs'

function useCircle (params = {}) {
  const options = Object.assign({
    angle: 0,
    radius: 20,
    direction: 1,
    duration: 2000,
    lifetime: 2000,
  }, params)

  const state = reactive({
    x: Math.cos(options.angle) * options.radius,
    y: Math.sin(options.angle) * options.radius,

    angle: options.angle,
    radius: options.radius,
    circumference: options.radius * 2 * Math.PI,

    duration: options.duration,
    lifetime: options.lifetime,
    direction: options.direction,

    speedFactor: 1,
    maxSpeed: 1.2,
    acceleration: false,

    step: computed(() => {
      return state.circumference / (state.duration * state.speedFactor)
    }),

    history: [],

    startAt: Date.now(),
  })

  function compute () {
    if (state.acceleration) {
      accelerate()
    } else {
      deccelerate()
    }

    rotate()
  }

  function render ({ context }, elapsed) {
    drawPoint(context, state)

    let index = 0 
    while (index < state.history.length) {
      const oldParticle = state.history[index]

      drawPoint(context, oldParticle)

      state.history[index].lifetime = Date.now() - oldParticle.startAt

      index++
    }

    state.history = state.history
      .filter(oldParticle => oldParticle.lifetime < state.lifetime)

    state.history.push({ 
      x: state.x, 
      y: state.y, 
      angle: state.angle,
      startAt: Date.now(),
      lifetime: 0,
    })
  }

  function drawPoint (context, { x, y, lifetime }) {
    context.beginPath()
    context.arc(x, y, 0.6, 0, Math.PI * 2)
    context.fill()
    context.closePath()

    lifetime--
  }

  function rotate (elapsed) {
    const now = Date.now()
    const delta = now - state.startAt 

    state.angle = (state.step * delta / state.radius) * state.direction

    state.x = (state.radius * Math.cos(state.angle)) + 40
		state.y = (state.radius * Math.sin(state.angle)) + 40
  }

  function accelerate () {
    const speed = Math.min(state.speedFactor + (0.001 * state.direction), state.maxSpeed)

    state.speedFactor = Math.max(1, speed)
  }

  function deccelerate () {
    const speed = Math.min(state.speedFactor - (0.001 * state.direction), state.maxSpeed)

    state.speedFactor = Math.max(1, speed)
  }

  function increaseSpeed () {
    state.acceleration = true
  }

  function decreaseSpeed () {
    state.acceleration = false
  }

  return {
    state,
    compute,
    render,
    increaseSpeed,
    decreaseSpeed,
  }
}

export function useVinylButton () {
  const canvas = ref(null)

  const state = reactive({
    context: null, 

    size: {
      width: 0,
      height: 0,
    },

    center: {
      x: 0,
      y: 0,
    },

    circles: [],

    startAt: Date.now(),
  })

  const { pause, resume } = useCanvasEngine({
    loop () {
      const elapsed = Date.now() - state.startAt

      let length = state.circles.length 
      let index = 0

      while (index < length) {
        state.circles[index].compute(state, elapsed)

        index++
      }
    },

    render () {
      if (!state.context) {
        return 
      }

      const elapsed = Date.now() - state.startAt

      state.context.clearRect(0, 0, state.size.width, state.size.height)

      let length = state.circles.length 
      let index = 0

      while (index < length) {
        state.circles[index].render(state, elapsed)

        index++
      }
    }
  })

  function init () {
    const pixelRatio = window.devicePixelRatio || 1

    canvas.value.width = canvas.value.offsetWidth * pixelRatio
    canvas.value.height = canvas.value.offsetHeight * pixelRatio

    state.context = canvas.value.getContext('2d')

    // state.context.translate(0 - (canvas.value.width / 2), 0 - (canvas.value.height / 2))
    state.context.scale(pixelRatio, pixelRatio)

    state.size.width = canvas.value.offsetWidth
    state.size.height = canvas.value.offsetHeight

    state.center.x = canvas.value.offsetWidth / 2
    state.center.y = canvas.value.offsetHeight / 2

    state.context.strokeStyle = '#ffffff'
    state.context.fillStyle = '#ffffff'

    state.circles.push(
      // useCircle({
      //   radius: 40,
      //   duration: 7500, 
      //   lifetime: 7500,
      // }),

      // useCircle({
      //   radius: 35,
      //   duration: 10000,
      //   lifetime: 7500,
      //   direction: -1,
      // }),

      useCircle({
        angle: 90,
        radius: 33,
        duration: 5000,
        lifetime: 2000,
      }),

      // useCircle({
      //   angle: 180,
      //   radius: 26,
      //   duration: 5000,
      //   lifetime: 500,
      //   direction: -1,
      // }),
    )
  }

  function increaseSpeed () {
    state.circles.forEach(circle => circle.increaseSpeed())
  }

  function decreaseSpeed () {
    state.circles.forEach(circle => circle.decreaseSpeed())
  }

  onMounted(() => {
    nextTick(() => {
      init()
    })
  })

  // pause()

  return {  
    state,
    canvas,
    increaseSpeed,
    decreaseSpeed,
  }
}

export default useVinylButton