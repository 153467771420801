import Vuex from 'vuex'

export default Vuex.createStore({
  state: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {

  }
})
