<template>
  <div class="relative menu h-20 w-20">
    <!-- <canvas 
      ref="canvas"
      class="h-20 w-20"
    ></canvas> -->

    <button
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center rounded-full outline-none focus:outline-none"
    >
      <div class="menu-icon"></div>
    </button>
      <!-- @mouseover="increaseSpeed"
      @mouseout="decreaseSpeed" -->
  </div>
</template>

<script>
  import { } from 'vue'
  import { useVinylButton } from '@composables/canvas/use-vinyl-button'
  
  export default {
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },

    setup () {
      // const { 
      //   state: button, 
      //   canvas,
      //   increaseSpeed,
      //   decreaseSpeed,
      // } = useVinylButton()

      return {
        // button,
        // canvas,
        // increaseSpeed,
        // decreaseSpeed,
      }
    },
  }
</script>

<style lang="scss">
  .menu {

    .menu-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 1px;
      width: 27px;
      background: $color-white;
      transform: translate3d(-50%, -50%, 0);
      transform-origin: top left;
      transition: transform 0.3s cubic-bezier(.25, .05, .1, .94);

      &:before, &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        height: 1px;
        width: 100%;
        background: $color-white;
        transform-origin: top left;
        transition: transform 0.3s cubic-bezier(.25, .05, .1, .94);
      }

      &:before {
        transform: translate3d(0, -5px, 0);
      }

      &:after {
        transform: translate3d(0, 5px, 0);
      }      
    }

    &:hover {
      .menu-icon {
        transform: translate3d(calc(-50% + 5px), -50%, 0);

        &:before {
          transform: translate3d(-7px, -5px, 0);
        }

        &:after {
          transform: translate3d(-5px, 5px, 0);
        }
      }
    }
}
</style>