import Browser from '@utils/browser-detect'
import deepMerge from '@utils/deep-merge'
import anime from 'animejs'
import { PerspectiveCamera, Vector3 } from 'three'

const FOV = 75
const ASPECT = window.innerWidth / window.innerHeight
const NEAR = 0.1
const FAR = 10000 

class WebGLPerspectiveCamera extends PerspectiveCamera {
  constructor (fov = FOV, aspect = ASPECT, near = NEAR, far = FAR, params = {}) {
    super(fov, aspect, near, far)

    this.params = deepMerge({
      name: 'WebGLPerspectiveCamera',
      position: {
        x: 0,
        y: 0, 
        z: Browser.isMobile ? 120 : 77,
      },
      target: {
        x: 0,
        y: 0, 
        z: 0,
      },
    }, params)

    this.name = this.params.name

    this.position.x = this.params.position.x,
    this.position.y = this.params.position.y,
    this.position.z = this.params.position.z

    this.lookAt(new Vector3(
      this.params.target.x,
      this.params.target.y,
      this.params.target.z
    ))

    if (process.env.NODE_ENV !== 'production') {
      console.debug(`[WebGLRenderer] Loaded with options:`, JSON.stringify({ fov, aspect, far, near }, null, 2))
      console.debug(`[WebGLRenderer] Loaded with params:`, JSON.stringify(this.params, null, 2))
    }
  }

  move (to, options = { easing: 'easeOutQuad', duration: 1000 }) {
    return new Promise(resolve => {
      let position = {
        x: this.position.x,
        y: this.position.y,
        z: this.position.z
      }
  
      if (typeof to.x === 'undefined') {
        to.x = position.x 
      }
  
      if (typeof to.y === 'undefined') {
        to.y = position.y 
      }
  
      if (typeof to.z === 'undefined') {
        to.z = position.z 
      }
  
      anime({
        targets: position,
        x: to.x,
        y: to.y,
        z: to.z,
        easing: options.easing,
        duration: options.duration,
        update: () => {
          this.position.x = position.x
          this.position.y = position.y
          this.position.z = position.z
        },
        complete: resolve
      })
    })
  }

  resize (width, height) {
    this.aspect = width / height
    this.updateProjectionMatrix()
  }
}

export default WebGLPerspectiveCamera