import '@styles/app.scss'
import { createApp } from 'vue'
import { useComponents } from './scripts/components'
import RouterLayout from '@layouts/router'
import router from './scripts/router'
import store from './scripts/store'

const app = createApp(RouterLayout)
  
useComponents(app)

app.use(router)
app.use(store)

app.mount('#app')
