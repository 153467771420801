import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import EventsEmitter from 'events'

export function useObserver (domElement, params = {}) {
  const bus = new EventsEmitter()
  const hasChildren = ref(false)

  let element = domElement
  let subSelector = null
  let observer = null 

  if (domElement.watch && domElement.element) {
    element = {
      value: domElement.watch
    }
    subSelector = domElement.element
  }

  onMounted(() => {
    nextTick(() => {
      const watchConfig = Object.assign({ 
        attributes: false, 
        childList: true, 
        subtree: true,
        characterData: true,
      }, params)
  
      observer = new MutationObserver(
        watchSlot()
      )
  
      observer.observe(element.value, watchConfig)

      if (subSelector) {
        const target = document.querySelector(subSelector)

        hasChildren.value = target && !!target.children.length
      } else {
        hasChildren.value = !!element.value.children.length
      }
    })
  })

  onUnmounted(() => {
    bus.removeAllListeners()
    observer && observer.disconnect()
  })

  function watchSlot () {
    return function callback () {
      nextTick(() => {
        if (subSelector) {
          const target = document.querySelector(subSelector)

          hasChildren.value = target && !!target.children.length
        } else {
          hasChildren.value = !!element.value.children.length
        }

        bus.emit('updated', hasChildren.value)
      })
    }
  }

  function on (eventName, handler) {
    bus.on(eventName, handler)
  }

  return { hasChildren, on }
}

export default useObserver