import useLazyRoute from '@composables/routing/use-lazy-route'

export default {
  path: '',
  name: 'home',

  components: {
    default: useLazyRoute({
      component: () => import(/* webpackChunkName: "main" */'@pages/home')
    }),
  },
}